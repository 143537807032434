import React, {useEffect, useState} from "react";
import {
    Avatar, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar,
    ListItemText, MenuItem, Paper, Select, Typography
} from "@mui/material";

import {child, onValue, set} from "firebase/database";

import {GUEST_LIST_REF} from "../../common/constants";

import {useSnackbar} from "notistack";
import Block from "../../components/Block";
import {Tabel} from "./Tabel";


const numberSort = (a, b) => {
    if (!a.tabel && !b.tabel) return 0;
    if (a.tabel && !b.tabel) return -1;
    if (!a.tabel && b.tabel) return 1;
    if (a.tabel < b.tabel) return -1;
    if (a.tabel < b.tabel) return 1;
    return 0;
}
const TabelList = () => {
    const {enqueueSnackbar} = useSnackbar();

    const [data, setData] = useState([]);
    const [families, setFamilies] = useState([]);
    const [missingNames, setMissingNames] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [number, setNumber] = useState(1);
    const [openPerson, setOpenPerson] = useState(null);
    const [tabelFilter, setFilter] = useState(0);

    useEffect(() => {
        onValue(GUEST_LIST_REF, snap => {
            if (!snap.exists()) return;
            const newList = []
            const missingList = []
            const familiesList = []
            snap.forEach(guestSnap => {
                const persons = guestSnap.val().promiseNames;
                const refusal = guestSnap.val().refusal;
                if (refusal) return;
                persons?.forEach(p => p.fullName !== "" && p.dinner ? newList.push({
                    ...p, key: guestSnap.val().key, extraName: guestSnap.val().name
                }) : missingList.push({...p, extraName: guestSnap.val().name}));
                familiesList.push(guestSnap.val());
            })
            setData(newList);
            setMissingNames(missingList);
            setFamilies(familiesList);
        })
    }, [])

    const handleChange = (event) => {
        setNumber(event.target.value);
    };

    const openDialog = element => (event) => {
        setDialogOpen(true);
        setOpenPerson(element);
    }

    const saveTableNumber = async (event) => {
        try {
            const promiseNames = families.find(f => f.key === openPerson.key)?.promiseNames
            ?.map(p => p.fullName === openPerson.fullName ? {...openPerson, tabel: number} : p);
            if (!promiseNames) throw new Error("Fehlende Personen");
            await set(child(GUEST_LIST_REF, `${openPerson.key}/promiseNames`), promiseNames)
            setData([...data.map((d) => d.fullName === openPerson.fullName ? {...openPerson, tabel: number} : d)])
            enqueueSnackbar('Gespeichert', {variant: 'success'})
            setDialogOpen(false);
            setOpenPerson(null);
            setNumber(1);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern', {variant: 'error',})
        }
    }


    return <Block>
        <Container maxWidth={"md"} sx={{
            maxHeight: t => `calc(100% - ${t.spacing(4)})`,
        }}>
            <Paper elevation={5}
                   sx={{
                       paddingTop: t => t.spacing(2),
                       paddingBottom: t => ({
                           xs: t.spacing(8),
                           md: t.spacing(4),
                       }),
                       paddingLeft: t => t.spacing(4),
                       paddingRight: t => t.spacing(4),
                       width: '100%',
                       overflow: "auto",
                       maxHeight: '100%',
                       boxSizing: 'border-box'
                   }}>
                <Typography variant={"h2"} sx={{marginBottom: 1, fontSize: '3rem',}}>Tischeinteilung {tabelFilter &&
                <span>{data?.filter(e => tabelFilter !== 0 ? e.tabel === tabelFilter : true).length}</span>}</Typography>
                <FormControl fullWidth sx={{pt: 2}}>
                    <InputLabel id="select-table">Tischnummer FLTER </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tabelFilter}
                        label="Tischnummer FLTER "
                        onChange={(e) => setFilter(e.target.value)}
                    >
                        <MenuItem value={0}>-- Alle --</MenuItem>
                        <MenuItem value={1}>Tisch 1</MenuItem>
                        <MenuItem value={2}>Tisch 2</MenuItem>
                        <MenuItem value={3}>Tisch 3</MenuItem>
                        <MenuItem value={4}>Tisch 4</MenuItem>
                        <MenuItem value={5}>Tisch 5</MenuItem>
                        <MenuItem value={6}>Tisch 6</MenuItem>
                        <MenuItem value={7}>Tisch 7</MenuItem>
                        <MenuItem value={8}>Tisch 8</MenuItem>
                        <MenuItem value={9}>Tisch 9</MenuItem>
                        <MenuItem value={10}>Tisch 10</MenuItem>
                        <MenuItem value={11}>Tisch 11</MenuItem>
                        <MenuItem value={12}>Tisch 12</MenuItem>
                        <MenuItem value={13}>Tisch 13</MenuItem>
                        <MenuItem value={14}>Tisch 14</MenuItem>
                        <MenuItem value={15}>Tisch 15</MenuItem>
                    </Select>
                </FormControl>
                <List>
                    {data?.sort(numberSort).filter(e => tabelFilter !== 0 ? e.tabel === tabelFilter || !e.tabel : true).map((g, i) => (
                        <ListItem key={i}>
                            <ListItemAvatar>
                                <IconButton onClick={openDialog(g)}>
                                    <Avatar>{g.tabel ?? '-'}</Avatar>
                                </IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={g.fullName} secondary={g.extraName}/>
                        </ListItem>
                    ))}
                </List>
                <Typography variant={"h3"} sx={{mt: 2, mb: 1, fontSize: '2rem',}}>Kontrollieren: Fehlender Name oder Kommen nicht zum Essen</Typography>

                <List sx={{mb: 4}}>
                    {missingNames?.map((g, i) => (
                        <ListItem key={i}>
                            <ListItemText primary={g.fullName} secondary={g.extraName}/>
                        </ListItem>
                    ))}
                </List>
                <Tabel persons={data?.filter(e => e.tabel === 1)} number={1}/>
                <Tabel persons={data?.filter(e => e.tabel === 2)} number={2}/>
                <Tabel persons={data?.filter(e => e.tabel === 3)} number={3}/>
                <Tabel persons={data?.filter(e => e.tabel === 4)} number={4}/>
                <Tabel persons={data?.filter(e => e.tabel === 5)} number={5}/>
                <Tabel persons={data?.filter(e => e.tabel === 6)} number={6}/>
                <Tabel persons={data?.filter(e => e.tabel === 7)} number={7}/>
                <Tabel persons={data?.filter(e => e.tabel === 8)} number={8}/>
                <Tabel persons={data?.filter(e => e.tabel === 9)} number={9}/>
                <Tabel persons={data?.filter(e => e.tabel === 10)} number={10}/>
                <Tabel persons={data?.filter(e => e.tabel === 11)} number={11}/>
                <Tabel persons={data?.filter(e => e.tabel === 12)} number={12}/>
                <Tabel persons={data?.filter(e => e.tabel === 13)} number={13}/>
                <Tabel persons={data?.filter(e => e.tabel === 14)} number={14}/>
                <Tabel persons={data?.filter(e => e.tabel === 15)} number={15}/>

            </Paper>
        </Container>

        <Dialog open={dialogOpen} fullWidth maxWidth={"md"}>
            <DialogTitle>Tischnummer Wählen</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{pt: 2}}>
                    <InputLabel id="select-table">Tischnummer </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={number}
                        label="Tischnummer"
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>Tisch 1</MenuItem>
                        <MenuItem value={2}>Tisch 2</MenuItem>
                        <MenuItem value={3}>Tisch 3</MenuItem>
                        <MenuItem value={4}>Tisch 4</MenuItem>
                        <MenuItem value={5}>Tisch 5</MenuItem>
                        <MenuItem value={6}>Tisch 6</MenuItem>
                        <MenuItem value={7}>Tisch 7</MenuItem>
                        <MenuItem value={8}>Tisch 8</MenuItem>
                        <MenuItem value={9}>Tisch 9</MenuItem>
                        <MenuItem value={10}>Tisch 10</MenuItem>
                        <MenuItem value={11}>Tisch 11</MenuItem>
                        <MenuItem value={12}>Tisch 12</MenuItem>
                        <MenuItem value={13}>Tisch 13</MenuItem>
                        <MenuItem value={14}>Tisch 14</MenuItem>
                        <MenuItem value={15}>Tisch 15</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => setDialogOpen(false)}>Abbrechen</Button>
                <Button variant={"contained"} onClick={saveTableNumber}>Speichern</Button>
            </DialogActions>
        </Dialog>
    </Block>
}

export default TabelList;
