import React from "react";
import {Box, Button, Container, Paper} from "@mui/material";
import mainPic from '../../assets/images/photo2.png';
import Block from "../../components/Block";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import PagePaper from "../../components/PagePaper";
import Paragraph from "../../components/Paragraph";

const basicFontFamily = '"Darker Grotesque"';

const Faq = () => {

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"FAQ"} />
            <HeaderTwoNoImage title={"in Arbeit"}/>
            <Paragraph>Liebe Gäste noch haben wir nicht alle Inhalte der Webseite ausgearbeitet, wir bitten um euren Verständnis.</Paragraph>
            <Paragraph>Solltet ihr im Menu einen Punkt neben der Seite sehen könnt ihr euch gewiss sein das es neue Inhalte oder Änderungen gibt.</Paragraph>
        </PagePaper>
    </Block>
}

export default Faq;
