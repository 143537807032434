import React from "react";
import {Box, Container, Paper, Typography} from "@mui/material";
import Block from "../../components/Block";
import TimeBlock from "./TimeBlock";
import party from "../../assets/icons/club-party.svg";
import dinner from "../../assets/icons/food-dinner.svg";
import glasses from "../../assets/icons/glass-drinks.svg";
import speech from "../../assets/icons/speak-speech.svg";
import cake from "../../assets/icons/wedding-cake.svg";
import couple from "../../assets/icons/wedding-couple.svg";
import reception from "../../assets/icons/wedding-reception-party.svg";
import rings from "../../assets/icons/wedding-rings.svg";
import castle from "../../assets/icons/castle.svg";
import drink from "../../assets/icons/drink.svg";

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PagePaper from "../../components/PagePaper";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";

const list = [
    {
        icon: castle,
        title: 'Einlass',
        time: '10:00 Uhr',
        text: 'Wer möchte kann bereits ab 10 Uhr die Anlage auf Schloss Hof besichtigen. Die Eintrittskarten sind auf eurem Namen hinterlegt.',
    },
    {
        icon: reception,
        title: 'Zusammenkunft',
        time: '13:30 Uhr',
        text: 'Wir bitten alle Gäste sich bereits um 13:30 Uhr im Festsaal einzufinden und Platz zu nehmen.',
    },
    {
        icon: rings,
        title: 'Trauung',
        time: '14:00 Uhr',
        text: 'Wir möchten zu dieser Zeit mit der Trauungszeremonie beginnen. Wer zu spät kommt, muss leider vor der Türe warten.',
    },
    {
        icon: couple,
        title: 'Trauzug',
        time: 'im Anschluss',
        text: 'Nun möchten wir als frisch verheiratetes Paar gemeinsam mit euch geschlossen zur nächsten Location zu Fuß (etwa 200m ) weiterziehen.\n' +
            '    Dort angekommen freuen wir uns über eure Gratulationen und Geschenke und machen zusammen schöne Erinnerungsfotos.',
    },
    {
        icon: glasses,
        title: 'Agape',
        time: '~15:00 Uhr',
        text: 'Nach den Gratulationen werdet ihr, als unsere Gäste, mit einem Gläschen Sekt & Co., aber auch schmackhaften Brötchen begrüßt.'
    },
    {
        icon: cake,
        title: 'Tortenanschnitt',
        time: '~16:30 Uhr',
        text: 'Eines der Highlights jeder Hochzeit ist bestimmt die Torte, zumindest sehen wir das so. Weshalb wir beschlossen haben diese nicht erst um Mitternacht zu präsentieren. Wir freuen uns gemeinsam mit euch das Kunstwerk unserer lieben Freundin Sabine zu bestaunen und zu verkosten.'
    },
    {
        icon: speech,
        title: 'Runder Tisch',
        time: '~18:00 Uhr',
        text: 'Wir bitten unsere Gäste um etwa 18 Uhr ihre Sitzplätze in der Orangerie einzunehmen und freuen uns auf ein paar Worte unserer Liebsten.'
    },
    {
        icon: dinner,
        title: 'Abendmenü',
        time: 'im Anschluss',
        text: 'Danach wird das vorher gewählte 3-Gänge Menü serviert und bei guter Laune und vielen Getränken gemeinsam genossen.'
    },
    {
        icon: party,
        title: 'Tanz & Co.',
        time: 'ab ~ 21:30 Uhr',
        text: 'Nun möchten wir unseren ersten Tanz als verheiratetes Paar in romantischerer Atmosphäre aufs Parkett legen und euch danach mit lustigen Spielen, Musik und einer ausgelassener Party erheitern.'
    }
]


const Timeline = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"TAGESABLAUF"} />
            <HeaderTwoNoImage title={"in Arbeit"}/>
            <Paragraph>Liebe Gäste noch haben wir nicht alle Inhalte der Webseite ausgearbeitet, wir bitten um euren Verständnis.</Paragraph>
            <Paragraph>Solltet ihr im Menu einen Punkt neben der Seite sehen könnt ihr euch gewiss sein das es neue Inhalte oder Änderungen gibt.</Paragraph>
        </PagePaper>
    </Block>
}

export default Timeline;


/*
  <Container maxWidth={"md"} sx={{
            maxHeight: t => `calc(100% - ${t.spacing(4)})`,
        }}>
            <Paper elevation={5}
                   sx={{
                       pt: 2,
                       paddingLeft: t => t.spacing(4),
                       paddingRight: t => t.spacing(4),
                       width: '100%',
                       overflow: "auto",
                       maxHeight: '100%',
                       boxSizing: 'border-box'
                   }}>
                <Typography variant={"h2"} component={"h1"}
                            sx={{marginBottom: 3, fontSize: {xs: '1.75rem', md: '2.25rem'}, textAlign: 'right', fontFamily: '"Darker Grotesque"'}}>TAGESABLAUF</Typography>

                <Box sx={{mb: '40px'}}><Typography variant={"h3"} sx={{marginBottom: 1, fontSize: {xs: '2.5rem', sm: '3rem'}}}>Unser Tag ♡</Typography></Box>
                { list.map((element, index) => (<TimeBlock isRight={index % 2 === 0  ? matches : true} icon={element.icon} time={element.time} text={element.text} title={element.title} />)) }
                <TimeBlock isRight={matches} endBlock/>
            </Paper>

        </Container>
 */


/*
                <TimeBlock isRight={matches} icon={party} time={"21:30"} title={"Beginn der Party"}
                           text={"Ab jetzt beginnt die Party, nun sind dinge wie erster Tanz, kleine Spiele und gemüdliches bei sammen sitzen angesagt."}/>
                <TimeBlock isRight icon={drink} time={"23:30"} title={"Ende des Festes"}
                           text={"Ab 23:30 wird unser Caterer anfangen abzubauen und es werden keine Getränke mehr ausgeschenkt. Das heißt wir gehen davon aus dass das Fest bald vorbei sein wird. Mal schauen wie es sich Entwickelt."}/>

 */
