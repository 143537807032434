import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {onValue} from "firebase/database";
import QRCode from 'qrcode.react';
import {GUEST_LIST_REF} from "../../common/constants";
import {Container, Typography} from "@mui/material";

const PrintQr = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        onValue(GUEST_LIST_REF, snap => {
            if (!snap.exists()) return;
            const newList = []
            snap.forEach(guestSnap => {
                newList.push(guestSnap.val());
            })
            console.log(newList)
            setData(newList);
        })
    }, [])


    return <Box>
        <Container sx={{maxWidth: "210mm", display: "flex", flexWrap: 'wrap'}}>
            {data
                .map((guest) => ({familyName: guest.name, key: guest.key}))
                .map((m, i) => (<Box sx={{
                        width: t => `calc(20% - 2 * ${t.spacing(1)})`, m: 1, mb: '42px', justifyContent: 'center', display: 'flex'
                    }}>
                        <Box sx={{ display: "flex", flexDirection: "column",  alignItems: 'center',
                            p:1, boxSizing: 'border-box' }}>
                            <Typography variant={"body1"} sx={{fontSize: '0.75rem'}}>Einladungscode</Typography>
                            <QRCode
                                id={m.key}
                                value={`https://marleneundjohannes.vermaehlung.at/overview?code=${m.key}`}
                                size={80}
                                level={"L"}
                                includeMargin={true}
                            />
                            <Typography variant={"body1"} sx={{fontSize: '0.75rem'}}>{m.key}</Typography>
                            <Typography variant={"body1"} sx={{textAlign: "center",fontSize: '0.75rem', mt: 2}}>{m.familyName}</Typography>
                        </Box>
                    </Box>)
                )}
        </Container>

    </Box>
}

export default PrintQr;
