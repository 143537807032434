import React from "react";
import Typography from "@mui/material/Typography";

const Paragraph = ({children, important, inset}) => {
    const baseStyling = {display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'};
    const addedImportantStyling = important ? {...baseStyling, p: 2,  fontSize: '1.5em', fontWeight: 'bold'} : baseStyling;
    const finishedStyling = inset ? {...addedImportantStyling, pl: 2} : addedImportantStyling;

    return <Typography
        variant={"p"}
        sx={finishedStyling}
    >
        {children}
    </Typography>

    }

export default Paragraph;
