import React from "react";
import {Box, Container, Paper} from "@mui/material";
import mainPic from '../../assets/images/geschenk-hochzeit-verpacken.jpeg';
import Block from "../../components/Block";
import Typography from "@mui/material/Typography";
import PagePaper from "../../components/PagePaper";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";

const basicFontFamily = '"Darker Grotesque"';

const Presents = () => {

    return <Block>

        <PagePaper>
            <HeaderOneNoImage title={"HOCHZEITSGESCHENKE"} />
            <HeaderTwoNoImage title={"in Arbeit"}/>
            <Paragraph>Liebe Gäste noch haben wir nicht alle Inhalte der Webseite ausgearbeitet, wir bitten um euren Verständnis.</Paragraph>
            <Paragraph>Solltet ihr im Menu einen Punkt neben der Seite sehen könnt ihr euch gewiss sein das es neue Inhalte oder Änderungen gibt.</Paragraph>
        </PagePaper>
    </Block>
}

export default Presents;

/*
 <Container maxWidth={"md"} sx={{
            maxHeight: t => `calc(100% - ${t.spacing(4)})`,
        }}>
            <Paper elevation={5}
                   sx={{
                       width: '100%',
                       overflow: "auto",
                       maxHeight: '100%',
                       boxSizing: 'border-box'
                   }}>
                <Box sx={{width: t => `100%`, position: 'relative', marginBottom: 4}}>
                    <img style={{width: '100%'}} src={mainPic} alt={"Schloss Hof"}/>
                </Box>
<Box sx={{px: 4, mb: 4, pb: 4}}>
    <Typography variant={"h2"} component={"h1"} sx={{fontSize: {xs: '2.5rem', md: '3rem'}, display: 'inline-block'}}>Hochzeitsgeschenke,</Typography>
    <Typography variant={"h3"} component={"h2"}
                sx={{
                    display: {xs: 'block', md: 'inline-block'}, marginLeft: t => ({
                        xs: t.spacing(0),
                        sm: t.spacing(2),
                    }), marginBottom: 2, fontSize: {xs: '1.25rem', sm: '1.75rem'}, textAlign: 'left', fontFamily: '"Darker Grotesque"'
                }}>unsere Wünsche</Typography>
    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>Das größte Geschenk von allen
        ist für uns, diesen wundervollen Tag mit euch zusammen zu feiern. Wenn ihr uns dennoch unterstützen wollt, haben wir folgende Wünsche,
        über die wir uns sehr freuen würden.</Typography>

    <Typography variant={"h3"} component={"h3"}
                sx={{
                    display: 'block', textAlign: 'left', marginBottom: 0, fontSize: '1.3em', fontWeight: 'bold', fontFamily: basicFontFamily,
                    textShadow: 'none'
                }}>GROSCHEN</Typography>
    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>Unser Haushalt ist komplett,
        darum fänden wir's ganz nett, schenkt uns keine schönen Sachen, lasst lieber unser Sparschwein lachen.</Typography>

    <Typography variant={"h3"} component={"h3"}
                sx={{
                    display: 'block', textAlign: 'left', marginBottom: 0, fontSize: '1.3em', fontWeight: 'bold', fontFamily: basicFontFamily,
                    textShadow: 'none'
                }}>REISEN</Typography>
    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>In die Ferne reisen wir gerne,
        weshalb wir wären sehr entzückt, wenn ihr uns auch hier beglückt. Ein Reisegutschein ohne Ablaufdatum wäre fein, dazu sagen wir auch
        bestimmt nicht „Nein“. </Typography>

    <Typography variant={"h3"} component={"h3"}
                sx={{
                    display: 'block', textAlign: 'left', marginBottom: 0, fontSize: '1.3em', fontWeight: 'bold', fontFamily: basicFontFamily,
                    textShadow: 'none'
                }}>FLITTERWOCHENZUSCHUSS</Typography>
    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>Auf einen Zuschuss für die
        Flitterwochen, werden wir nicht pochen. Freuen würde uns es allemal, Strand und Meer wären hier unsere Wahl.</Typography>

    <Typography variant={"h3"} component={"h3"}
                sx={{
                    display: 'block', textAlign: 'left', marginBottom: 0, fontSize: '1.3em', fontWeight: 'bold', fontFamily: basicFontFamily,
                    textShadow: 'none'
                }}>GUTSCHEINE</Typography>
    <Typography variant={"body1"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>Auch ein Gutschein für Bau-,
        Möbelhäuser oder so mancher amerikanische Onlineshop wären der Hit, als finanzielle Unterstützung für unseren neuen
        Lebensabschnitt.</Typography>

</Box>
</Paper>
</Container>
 */
