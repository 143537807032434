import React from "react";
import {
    Navigate,
    useLocation
} from 'react-router-dom';
import {useAuth} from "../AuthProvider";

const ProtectedRoute = ({ children, needAdmin }) => {
    const { user } = useAuth();
    const location = useLocation();


    if (!user) return <Navigate to="/" replace state={{ from: location }} />;

    if(needAdmin && !user.isAdmin) return <Navigate to={"/noPermission"} replace state={{ from: location }} />;

    return children;
};

export default ProtectedRoute;
