import React from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";

const PagePaper = React.forwardRef(({children, withImage}, ref) => {
    return <Container
        maxWidth={"md"}
        sx={{
            maxHeight: t => `calc(100% - ${t.spacing(4)})`,
        }}
    >
        {withImage ?
            <Paper
                ref={ref}
                elevation={5}
                sx={{
                    width: '100%',
                    overflow: "auto",
                    maxHeight: '100%',
                    boxSizing: 'border-box'
                }}
            >
                {children}
            </Paper> :
            <Paper
                ref={ref}
                elevation={5}
                sx={{
                    paddingTop: t => t.spacing(2),
                    paddingBottom: t => ({
                        xs: t.spacing(8),
                        md: t.spacing(4),
                    }),
                    paddingLeft: t => t.spacing(4),
                    paddingRight: t => t.spacing(4),
                    width: '100%',
                    overflow: "auto",
                    maxHeight: '100%',
                    boxSizing: 'border-box'
                }}
            >
                {children}
            </Paper>}
    </Container>


})

export default PagePaper;
