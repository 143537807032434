import React from "react";
import {
    Home, NoMatch, Overview, GuestList,
    Promise, Impressum, Location, Timeline,
    Presents, Meal, TabelList, Kontakt,
    Foto, Faq, PrintQr, GuestStats,
    PrintMeal
} from "./routes";
import {Route, Routes} from "react-router-dom";
import {Navigation, ProtectedRoute} from "./components";
import {useAuth} from "./components/AuthProvider";
import {LinearProgress} from "@mui/material";

function App() {
    const {userLoading} = useAuth();


    if(userLoading) return <LinearProgress />
    return (
        <Routes>
            <Route element={<Navigation/>}>
                <Route path="/overview" element={<ProtectedRoute><Overview/></ProtectedRoute>}/>
                <Route path="/promise" element={<ProtectedRoute><Promise /></ProtectedRoute>}/>
                <Route path="/location" element={<ProtectedRoute><Location /></ProtectedRoute>}/>
                <Route path="/meal" element={<ProtectedRoute><Meal /></ProtectedRoute>}/>
                <Route path="/presents" element={<ProtectedRoute><Presents /></ProtectedRoute>}/>
                <Route path="/timeline" element={<ProtectedRoute><Timeline /></ProtectedRoute>}/>
                <Route path="/kontakt" element={<ProtectedRoute><Kontakt /></ProtectedRoute>}/>
                <Route path="/foto" element={<ProtectedRoute><Foto /></ProtectedRoute>}/>
                <Route path="/faq" element={<ProtectedRoute><Faq /></ProtectedRoute>}/>
                <Route path="/list" element={<ProtectedRoute needAdmin><GuestList/></ProtectedRoute>}/>
                <Route path="/tabel" element={<ProtectedRoute needAdmin><TabelList/></ProtectedRoute>}/>
                <Route path="/stats" element={<ProtectedRoute needAdmin><GuestStats/></ProtectedRoute>}/>
                <Route path="/print" element={<ProtectedRoute needAdmin><PrintMeal/></ProtectedRoute>}/>
                <Route path="/printqr" element={<ProtectedRoute needAdmin><PrintQr/></ProtectedRoute>}/>
            </Route>
            <Route path="/" element={<Home/>}/>
            <Route path="/impressum" element={<Impressum/>}/>
            <Route path="/noPermission" element={<NoMatch/>}/>
            <Route path="*" element={<NoMatch/>}/>
        </Routes>
    );
}

export default App;
