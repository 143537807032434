import React from "react";
import {Box, Link as HtmlLink} from "@mui/material";
import mainPic from '../../assets/images/eventlocation-schloss-hof-wien.jpeg';
import Block from "../../components/Block";
import Typography from "@mui/material/Typography";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import PagePaper from "../../components/PagePaper";
import PageImage from "../../components/PageImage";
import HeaderOneImage from "../../components/HeaderOneImage";
import HeaderTwoImage from "../../components/HeaderTwoImage";
import Paragraph from "../../components/Paragraph";
import HeaderThreeImage from "../../components/HeaderThreeImage";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";

const containerStyle = {
    width: '100%',
    height: '400px',
    position: 'relative',
};

const center = {
    lat: 48.21630403870035,
    lng: 16.93358205623548
};

const parkplatz = {
    lat: 48.21630403870035,
    lng: 16.93358205623548
};

const eingang = {
    lat: 48.2154261247134,
    lng: 16.93569491151932
};

const MyLink = ({href, label}) => (<HtmlLink
    sx={{
        fontWeight: 'bold',
        color: t => t.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }}
    target={"_blank"}
    href={href}
    rel={"noreferrer"}
>{label}</HtmlLink>)

const Location = () => {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAbM-tpvMdDr-0x2q7pAnQZ7o3lqFL4h3s"
    })

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"LOCATION"} />
            <HeaderTwoNoImage title={"in Arbeit"}/>
            <Paragraph>Liebe Gäste noch haben wir nicht alle Inhalte der Webseite ausgearbeitet, wir bitten um euren Verständnis.</Paragraph>
            <Paragraph>Solltet ihr im Menu einen Punkt neben der Seite sehen könnt ihr euch gewiss sein das es neue Inhalte oder Änderungen gibt.</Paragraph>
        </PagePaper>
    </Block>
}

export default Location;

/*
 <PagePaper withImage>
            <PageImage alt={"Schloss Hof"} src={mainPic}/>

<Box sx={{px: 4}}>
    <HeaderOneImage title={"Hier und da,"}/>
    <HeaderTwoImage title={"werden wir sehen"}/>
    <Paragraph>
        Liebe Freunde und Familie,
    </Paragraph>
    <Paragraph>
        an dieser seite wird gerade gearbeitet sobald alle infos bereit stehen melden wir uns.
    </Paragraph>

</Box>
{isLoaded ? (
    <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        options={{gestureHandling: 'cooperative'}}
    >
        <Marker
            position={parkplatz}
            label={"Parkplatz"}
        />
        <Marker
            position={eingang}
            label={"Eingang"}
        />
    </GoogleMap>
) : <></>}

</PagePaper>
 */
