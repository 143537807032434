import React, {useState} from "react";
import {Avatar, CircularProgress, Collapse, Container, styled} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useAuth} from "../../components/AuthProvider";
import Block from "../../components/Block";
import Paper from "@mui/material/Paper";

const LoginBox = styled(Box)(({theme}) => ({
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
}))

const Home = () => {
    const {onInvite, onLogin, register, inviteLoading, registerLoading, loginLoading} = useAuth();
    const authErrorMessage = useAuth().errorMessage;
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [inviteCode, setInviteCode] = useState('')
    const [mailAddress, setMailAddress] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)

    const openLogin = () => {
        setIsLoginOpen(true);
    }

    const handleLogin = () => {
        setIsLoginOpen(false);
        onLogin(mailAddress, password);
        setPassword('');
    }

    const closeLogin = () => {
        setIsLoginOpen(false);
    }

    const handleRegister = () => {
        if (password !== password2) {
            setPassword2('');
            return setErrorMessage('Passwörter stimmen nicht überein');
        }
        register(mailAddress, password);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        return isRegister ? handleRegister() : handleLogin()
    }

    const handleInvite = e => {
        onInvite(inviteCode);
    }

    // <Typography variant={"h2"} component={"h1"} sx={{textAlign: 'center'}}>Hochzeit von Ramona & Florian</Typography>

    return <Block>
        <Container maxWidth={"md"}>
            <Paper elevation={5} sx={{p: 5}}>
                <Typography variant={"h2"} component={"h1"} sx={{textAlign: 'center'}}>Hochzeit von Marlene & Johannes</Typography>
                {!!authErrorMessage && <Box sx={{color : t => t.palette.error.main}}>{authErrorMessage}</Box> }
                <TextField
                    id="code"
                    label="Einladungscode eingeben"
                    type="text"
                    value={inviteCode}
                    onChange={(e) => setInviteCode(e.target.value)}
                    autoComplete="inventation-code"
                    variant="outlined"
                    size={"small"}
                    fullWidth
                    disabled={inviteLoading}
                />
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button sx={{marginTop: 1}}
                            variant={"outlined"}
                            color={"primary"}
                            size={"small"}
                            disabled={inviteLoading}
                            startIcon={inviteLoading && <CircularProgress size={20}/>}
                            onClick={handleInvite}>Abschicken</Button>
                </Box>

            </Paper>
        </Container>
        <LoginBox>
            <Avatar sx={{backgroundColor: "white"}}>
                <IconButton onClick={openLogin}><Icon>admin_panel_settings</Icon> </IconButton>
            </Avatar>
        </LoginBox>

        <Dialog open={isLoginOpen} onClose={closeLogin}>
            <DialogTitle>
                <Collapse in={!isRegister}>Login als Organisator</Collapse>
                <Collapse in={isRegister}>Registrieren</Collapse>

            </DialogTitle>
            <Box
                component="form"
                noValidate
                autoComplete="on"
                onSubmit={handleSubmit}
            >
                <DialogContent>
                    <Collapse in={isRegister}>
                        <Typography variant={"p"}>
                            Bitte nur Registrieren wen wir dich als Organisator ausgewählt haben, da die eine Registration zurzeit nichts bringen sollte.
                        </Typography>
                    </Collapse>
                    <TextField
                        id="mail"
                        label="E-Mail"
                        type="text"
                        value={mailAddress}
                        onChange={(e) => setMailAddress(e.target.value)}
                        autoComplete="mail"
                        variant="standard"
                        size={"medium"}
                        autoFocus
                        fullWidth
                        disabled={loginLoading || registerLoading}
                    />
                    <TextField
                        id="password"
                        label="Passwort"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="password"
                        variant="standard"
                        size={"medium"}
                        fullWidth
                        disabled={loginLoading || registerLoading}
                    />
                    <Collapse in={isRegister}>
                        <TextField
                            id="password2"
                            label="Passwort wiederholen"
                            type="password"
                            error={!!errorMessage}
                            helperText={errorMessage}
                            value={password2}
                            onChange={(e) => {
                                setPassword2(e.target.value);
                                setErrorMessage(null)
                            }}
                            autoComplete="password"
                            variant="standard"
                            size={"medium"}
                            fullWidth
                            disabled={registerLoading}
                        />
                    </Collapse>
                </DialogContent>
                {!isRegister && <DialogActions>
                    <Button variant={"text"} color={"neutral"} onClick={() => setIsRegister(true)} disabled={loginLoading}>register</Button>
                    <Button variant={"text"}
                            color={"primary"}
                            onClick={handleLogin}
                            type={"submit"}
                            startIcon={loginLoading && <CircularProgress size={20}/>}
                            disabled={loginLoading}>Login</Button>
                </DialogActions>}
                {isRegister && <DialogActions>
                    <Button variant={"text"} color={"neutral"} onClick={() => setIsRegister(false)} type={"reset"} disabled={registerLoading}>abbrechen</Button>
                    <Button variant={"text"}
                            color={"primary"}
                            onClick={handleRegister}
                            type={"submit"}
                            startIcon={registerLoading && <CircularProgress size={20}/>}
                            disabled={registerLoading}>Save</Button>
                </DialogActions>}
            </Box>
        </Dialog>
    </Block>
}

export default Home;
