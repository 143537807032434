import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {
    Button, FormControl, FormLabel, Paper, RadioGroup, Checkbox, FormControlLabel, FormGroup, Typography, Box, Radio, TextField,
    InputAdornment, LinearProgress
} from "@mui/material";
import {useAuth} from "../../components/AuthProvider";
import {EMPTY_GUEST, EMPTY_MEAL, GUEST_LIST_REF} from "../../common/constants";
import {child, onValue, set} from "firebase/database";
import {useSnackbar} from 'notistack';
import Block from "../../components/Block";
import {hasPromise, missingNames} from "../../common/statelessFunctions";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";
import ParagraphHeader from "../../components/ParagraphHeader";
import PagePaper from "../../components/PagePaper";

const Meal = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {code, user} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [guestData, setGuestData] = useState(EMPTY_GUEST);

    useEffect(() => {
        if (code) {
            onValue(child(GUEST_LIST_REF, code), snap => {
                if (!snap.exists()) return;
                const data = snap.val();
                if (!data.meal) {
                    const nextGuestData = {...EMPTY_GUEST, meal: data.promiseNames.filter(d => d.dinner).map(g => ({...EMPTY_MEAL, name: g.fullName})), ...data}
                    setGuestData(nextGuestData);
                    set(child(GUEST_LIST_REF, `${data.key}/meal`), nextGuestData.meal).then();
                } else if (data.meal) {
                    const promise = data.promiseNames?.filter(d => d.dinner).map(p => p.fullName).filter(n => n?.trim() !== "") ?? [];
                    const meals = data.meal.map(m => promise.indexOf(m.name) > -1 ? m : null).filter(m => !!m);
                    if (meals.length === promise.length) { setGuestData({...data, meal: meals}); } else {
                        const nextMeals = promise.map(n => meals.find(m => m.name === n) ? meals.find(m => m.name === n) : {...EMPTY_MEAL, name: n});
                        setGuestData({...data, meal: nextMeals});
                        set(child(GUEST_LIST_REF, `${data.key}/meal`), nextMeals).then();
                    }
                }

                setIsLoading(false);

            })
        }
    }, [code]);

    const handleChange = fullName => (event) => {
        const {value, name} = event.target;
        const meals = guestData.meal.map(m => m.name === fullName ? {...m, [name]: value} : m);
        setGuestData({...guestData, meal: meals});
    };

    const handleCheckChild = fullName => event => {
        const {checked, name} = event.target;
        let meals = guestData.meal.map(m => m.name === fullName ? {...m, [name]: checked} : m);
        if (name === "child" && !checked) meals = meals.map(m => m.name === fullName ? {...m, desert: true} : m);
        setGuestData({...guestData, meal: meals});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await set(child(GUEST_LIST_REF, `${guestData.key}/meal`), guestData.meal)
            enqueueSnackbar('Gespeichert und abgeschickt. Wir sehen nun, was du siehst.', {
                variant: 'success',
            })
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern und Senden', {
                variant: 'error',
            })
        }
    }

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"MENÜAUSWAHL"} />
            <HeaderTwoNoImage title={"in Arbeit"}/>
            <Paragraph>Liebe Gäste noch haben wir nicht alle Inhalte der Webseite ausgearbeitet, wir bitten um euren Verständnis.</Paragraph>
            <Paragraph>Solltet ihr im Menu einen Punkt neben der Seite sehen könnt ihr euch gewiss sein das es neue Inhalte oder Änderungen gibt.</Paragraph>
        </PagePaper>
    </Block>
}

export default Meal;

/*



        <PagePaper>
            <HeaderOneNoImage title={"MENÜAUSWAHL"}/>
            <HeaderTwoNoImage title={`${user.displayName},`}/>
            <Paragraph>
                wir möchten {guestData.invitations > 1 ? 'euch' : 'dir'} unsere Menüauswahl für unseren besonderen Tag vorstellen.
            </Paragraph>
            <Paragraph>
                Das Abendessen wird serviert und es wird drei Gänge geben. Wir sind keine Fans von Buffets und mögen es lieber an diesem Tag verwöhnt zu werden.
                So sollt auch ihr, als unsere Gäste, nicht für euer Essen anstehen müssen.
            </Paragraph>
            <Paragraph>
                Im Anschluss könnt ihr eure Menüauswahl, die an diesem Tag für euch dann vorbereitet wird, bis spätestens 22.07.2022 festlegen.
            </Paragraph>
            <Paragraph>
                Bei Unverträglichkeiten meldet euch bitte persönlich bei uns.
            </Paragraph>
            <ParagraphHeader title={"Speisekarte"}/>
            {isLoading && <LinearProgress/>}
            {!isLoading && hasPromise(guestData) !== 2 && <Box>
                <Paragraph important>
                    Leider hast du uns keine Zusage gegeben, deswegen darfst du jetzt auch nicht mitabstimmen!
                </Paragraph>
            </Box>}

            {!isLoading && hasPromise(guestData) === 2 && missingNames(guestData) && <Box>
                <Paragraph important>
                    Leider besitzt einer der Tischkarten zu dieser Einladung noch keinen Namen,
                    bitte den Namen ausfüllen oder bei einer Absage die Tischkarte löschen. <br/>
                    <Link to={'/promise'}>Einladung</Link>
                </Paragraph>
            </Box>}

            {!isLoading && guestData?.meal && hasPromise(guestData) === 2 && !missingNames(guestData) &&
            <Box component={"form"} noValidate autoComplete="on" onSubmit={handleSubmit}>
                {guestData.meal.map((m, i) => (<Paper key={i} elevation={2} sx={{p: 2, boxSizing: 'border-box', marginTop: 1, marginBottom: 2}}>
                    <Typography variant={"h3"} sx={{marginBottom: 1, fontSize: '2rem',}}>{m.name}</Typography>
                    <FormControl fullWidth>
                        <FormLabel id="demo-radio-buttons-group-label">SUPPE</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="soupOne"
                            name="soup"
                            value={m.soup}
                            onChange={handleChange((m.name))}
                        >
                            <FormControlLabel value="" sx={{py: 2}} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                              label="Keine Auswahl getroffen"/>
                            <FormControlLabel value="soupOne" sx={{py: 2}} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                              label="Hochzeitssuppe mit Grießnockerl, Leberknödel und/oder Frittaten mit frischem Schnittlauch"/>
                            <FormControlLabel value="SoupTwo" sx={{py: 2}} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                              label="Pikante Spitzpaprikacremesuppe mit Zitronen-Thymianschaum"/>
                        </RadioGroup>
                    </FormControl>


                    <FormControl fullWidth>
                        <FormLabel id="demo-radio-buttons-group-label2">HAUPTGANG</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label2"
                            defaultValue="mainOne"
                            name="main"
                            value={m.main}
                            onChange={handleChange((m.name))}
                        >
                            <FormControlLabel value="" sx={{py: 2}} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                              label="Keine Auswahl getroffen"/>
                            <FormControlLabel value="mainOne" sx={{py: 2}} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                              label="Wiener Schnitzel mit Petersilienkartoffeln, Preiselbeeren und Ketchup dazu ein Salatteller mit Gurken, Karotten, Tomaten und Vogerlsalat"/>
                            <FormControlLabel value="mainTwo" sx={{py: 2}} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                              label="Steirische Hühnerbrust mit Mozzarella und Basilikum gefüllt auf Safran-Risotto dazu Rosmarinsauce "/>
                            <FormControlLabel value="mainThree" sx={{py: 2}} control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                              label="Safran-Risotto dazu Pepperonata und Kräutersalat "/>
                        </RadioGroup>
                    </FormControl>

                    <FormGroup>
                        <FormLabel>NACHSPEISE, bekommt jeder</FormLabel>
                        <FormControlLabel sx={{color: '#333', '.MuiFormControlLabel-label.Mui-disabled': {color: '#333'}}}
                                          control={<Checkbox name={"desert"} checked={m.desert} disabled={!m.child} onChange={handleCheckChild(m.name)}/>}
                                          label="Schokoladiger Genuss mit Effekt "
                                          labelPlacement="end"/>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Ich bin ein KIND / BABY</FormLabel>
                        <FormControlLabel sx={{color: '#333'}} control={<Checkbox name={"child"} checked={m.child} onChange={handleCheckChild(m.name)}/>}
                                          label="Ja"
                                          labelPlacement="end"/>
                        {m.child &&
                        <FormControlLabel sx={{color: '#333'}} control={<Checkbox name={"chair"} checked={m.chair} onChange={handleCheckChild(m.name)}/>}
                                          label="Ich benötige einen Hochstuhl."
                                          labelPlacement="end"/>}
                        {m.child && <TextField type={"number"} size="small" name={"age"} fullWidth value={m.age} onChange={handleChange(m.name)}
                                               helperText={"Das Alter wird für unsere Abrechung benötigt."}
                                               InputProps={{
                                                   endAdornment: <InputAdornment position="end">Jahre</InputAdornment>,
                                               }}/>}
                    </FormGroup>

                    <Typography variant={"p"} sx={{display: 'block', py: 2, textAlign: 'left', fontSize: '1rem'}}>
                        Diese Person kommt doch nicht zur Hochzeit oder kann nicht bis zum Abendessen bleiben ?
                        Dann bitte dies in der <Link to={'/promise'}>Einladung</Link> zu ändern.
                    </Typography>

                </Paper>))}

                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', gap: 16, marginTop: 16}}>
                    <Button sx={{minWidth: 160}} variant={"outlined"} color={"primary"} type={"submit"}>Daten Speichern und Senden</Button>
                </div>
            </Box>}


        </PagePaper>
 */
